<template>
  <AutoComplete v-model="internalModel" :suggestions="filteredContacts" @complete="searchContact" field="namePostcode"
                :minLength=3 placeholder="Search contact..." :forceSelection="forceSelection">
    <template #item="{ item, index }">
      <div class="autocomplete-row">
        <div class="autocomplete-name" :title="item.name">
          {{ item.name }}
        </div>
        <div class="autocomplete-code">{{ item.postcode }}</div>
      </div>
    </template>
  </AutoComplete>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return ""
      }
    },
    is_customer: Boolean,
    is_supplier: Boolean,
    is_agent: Boolean,
    is_individual: Boolean,
    forceSelection: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    internalModel: {
      get() {
        this.$emit('itemSelected', this.value);
        return this.value.namePostcode;
      },
      set(newValue) {

        if (typeof newValue === 'string' && !this.isContactInList(newValue)) {

          const customValue = {contact_id: null, name: newValue, namePostcode: newValue};
          this.$emit('input', customValue);
          this.$emit('itemSelected', customValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },
  data() {
    return {
      filteredContacts: []
    };
  },
  methods: {
    async searchContact(event) {
      if (!event.query.trim()) return;

      try {
        const queryParams = this.buildQueryParams(event.query);
        const response = await this.ContactService.searchContactsWithType(queryParams);
        const formattedResponse = response.map(item => ({
          ...item, address: item.address, postcode: item.address && item.address.postcode ? item.address.postcode : "",
          namePostcode: item.address && item.address.postcode ? `${item.name} - ${item.address.postcode}` : ""
        }));
        this.filteredContacts = formattedResponse;
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    },
    buildQueryParams(searchTerm) {
      return {
        searchTerm,
        is_customer: this.is_customer,
        is_supplier: this.is_supplier,
        is_agent: this.is_agent,
        is_individual: this.is_individual
      };
    },
    isContactInList(name) {
      return this.filteredContacts.some(contact => contact.name === name);
    }
  }
};
</script>

<style>
::v-deep .p-datatable-thead {
  display: none;
}

.autocomplete-row {
  display: flex;
  justify-content: space-between;
}

.autocomplete-code,
.autocomplete-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-code {
  width: 25%;
}

.autocomplete-name {
  width: 75%;
}

.p-autocomplete-panel {
  max-width: 100%;
  width: auto;
}

.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-header .p-datatable-thead {
  display: none;
}

.product-code-column {
  width: 20%;
}

.product-name-column {
  width: 70%;
}

.action-column {
  width: 10%;
}
</style>
