<template>
    <form @submit.prevent="submitLocation" autocomplete="off"
        class="w-full max-h-screen bg-background flex flex-col relative" :class="{ 'overflow-y-hidden': isBusy }">
        <div v-show="isBusy" class="absolute inset-0 bg-faint-white flex items-center justify-center z-10">
            <spinner :color="'text-black'" :size="10" />
        </div>
        <page-header :title="pageTitle" back-to="loan-equipment">
            <Button @click="saveLocation" type="button" :disabled="!canSave"
                class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1">
                <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" viewBox="0 0 24 24">
                    <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
                    <path d="M17 21v-8H7v8M7 3v5h8" />
                </svg>
                <span>Save Changes</span>
            </Button>
        </page-header>

        <main class="px-6 bg-background flex-1 overflow-y-scroll">
            <!-- Current Status -->
            <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
                <div class="mt-6">
                    <div class="flex items-center mb-5">
                        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                            Equipment Status
                        </h3>
                        <hr class="flex-1 border-primary ml-4" />
                    </div>
                    <div class="flex flex-wrap -my-3 -mx-5">
                        <div class="w-full flex flex-col mt-3">
                            <div class="flex flex-wrap">
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Code</label>
                                    <input type="text" v-model="loanEquipment.product_code"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Current Status</label>
                                    <input type="text" v-model="currentEquipmentLoan.status"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Location</label>
                                    <input type="text" v-model="currentEquipmentLoan.current_location.name"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">INVOICE / WARRANTY NUMBER</label>
                                    <input type="text" v-model="currentEquipmentLoan.invoice_warranty_number"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                            </div>
                        </div>

                        <div class="w-full flex flex-col mt-3">
                            <div class="flex flex-wrap">
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">Description</label>
                                    <input type="text" v-model="loanEquipment.description"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">SERIAL NUMBER</label>
                                    <input type="text" v-model="loanEquipment.serial_number"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">CALIBRATION EXPIRY DATE</label>
                                    <input type="text" class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled
                                        v-model="calibration_date_display"
                                        :style="{ color: getTextColor(calibration_date) }" />
                                </div>
                                <div class="w-full sm:w-1/3 lg:w-1/4 flex flex-col px-5 mb-3">
                                    <label class="uppercase font-semibold text-sm">user</label>
                                    <input type="text" v-model="currentEquipmentLoan.created_by"
                                        class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- New Location -->
            <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
                <div class="mt-6">
                    <div class="flex items-center mb-5">
                        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                            New Location
                        </h3>
                        <hr class="flex-1 border-primary ml-4" />
                    </div>
                    <div class="grid grid-rows-2 grid-flow-col gap-4">
                        <div class="flex flex-col mb-3">
                            <label class="uppercase font-semibold text-sm">Set New Status</label>
                            <Dropdown v-model="newLocation.status" :options="statusList" placeholder="<New Status>" />
                        </div>
                        <div class="flex flex-col mb-3">
                            <label class="uppercase font-semibold text-sm">Date Of Action</label>
                            <Calendar v-model="newLocation.start_at_string" :showIcon="true" dateFormat="dd-mm-yy" />
                        </div>
                        <div class="row-span-2 flex flex-col mb-3">
                            <label class="uppercase font-semibold text-sm">Set New Location</label>
                            <ContactWithAddress v-model="location['AT CUSTOMER']" :is_customer="true"
                                @itemSelected="handleItemSelected"
                                v-if="newLocation.status === undefined || newLocation.status === 'AT CUSTOMER'" />
                            <ContactWithAddress v-model="location['IN STOCK']" :is_supplier="true" :is_agent="true"
                                @itemSelected="handleItemSelected" v-if="newLocation.status === 'IN STOCK'" />
                            <StaffAutocomplete v-model="location['INSTOCK (VAN)']"
                                v-if="newLocation.status === 'INSTOCK (VAN)'" />
                            <input type="text" v-model="location['UNDER SERVICE / REPAIR'].name"
                                v-if="newLocation.status === 'UNDER SERVICE / REPAIR'"
                                class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" readonly />
                        </div>
                        <div class="row-span-2 flex flex-col  mb-3">
                            <label class="uppercase font-semibold text-sm">Address</label>
                            <textarea v-if="newLocation.status === undefined" rows="5"
                                class="bge-input bge-input-spacing rounded" />
                            <textarea v-model="address['IN STOCK']" v-if="newLocation.status === 'IN STOCK'" rows="5"
                                class="bge-input bge-input-spacing rounded" />
                            <textarea v-model="address['AT CUSTOMER']" v-if="newLocation.status === 'AT CUSTOMER'" rows="5"
                                class="bge-input bge-input-spacing rounded" />
                            <textarea v-if="newLocation.status === 'INSTOCK (VAN)'" v-model="address['INSTOCK (VAN)']"
                                rows="5" class="bge-input bge-input-spacing rounded" readonly />
                            <textarea v-if="newLocation.status === 'UNDER SERVICE / REPAIR'"
                                v-model="address['UNDER SERVICE / REPAIR']" rows="5"
                                class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" readonly />
                        </div>
                        <div class="flex flex-col  mb-3">
                            <label class="uppercase font-semibold text-sm">INVOICE / WARRANTY NUMBER</label>
                            <input type="text" v-model="newLocation.invoice_warranty_number"
                                class="bge-input bge-input-spacing rounded" />
                        </div>
                        <div class="flex flex-col  mb-3">
                            <label class="uppercase font-semibold text-sm">user</label>
                            <input type="text" v-model="userName" class="bge-input bge-input-spacing rounded pointer-events-none bg-gray-200 text-gray-600" readonly />
                        </div>
                    </div>
                </div>
            </div>

            <!-- History -->
            <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
                <div class="mt-6">
                    <div class="flex items-center mb-5">
                        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                            History
                        </h3>
                        <hr class="flex-1 border-primary ml-4" />
                    </div>
                    <LoanEquipmentHistory :loanEquipmentHistory="loanEquipmentHistory" />
                </div>
            </div>
        </main>
    </form>
</template>
  
<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import ContactWithAddress from '@/components/AutoComplete/ContactWithAddress.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import LoanEquipmentHistory from '@/components/LoanEquipment/LoanEquipmentHistory.vue';
import StaffAutocomplete from '@/components/AutoComplete/StaffAutoComplete.vue';
import notificationMixin from "@/mixins/notificationMixin";

export default {
    name: "ManageLoanEquipment",
    mixins: [notificationMixin],
    components: {
        PageHeader,
        Panel,
        Spinner,
        ContactWithAddress,
        AppButton,
        Icon,
        LoanEquipmentHistory,
        StaffAutocomplete
    },
    props: {
        loanEquipmentId: String,
    },
    data() {
        return {
            isBusy: false,
            loanEquipment: {},
            loanEquipmentHistory: [],
            activeIndex: 0,
            newLocation: { start_at_string: new Date() },
            currentEquipmentLoan: { status: '', invoice_warranty_number: '', current_location: { name: '' } },
            statusList: [],
            van_address: 'BOSTON ENGINEER',
            repair_address: 'REPAIR DEPARTMENT',
            location: {
                'IN STOCK': '',
                'INSTOCK (VAN)': '',
                'AT CUSTOMER': '',
                'UNDER SERVICE / REPAIR': { contact_id: null, name: 'REPAIR DEPARTMENT' }
            },
            address: {
                'IN STOCK': '',
                'INSTOCK (VAN)': 'BOSTON ENGINEER',
                'AT CUSTOMER': '',
                'UNDER SERVICE / REPAIR': 'REPAIR DEPARTMENT'
            },
            calibration_date: null,
            calibration_date_display: null,
        };
    },
    async mounted() {
        if (!this.loanEquipmentId)
            return;
        await this.loadLoanEquipment();
        await this.loadStatusList();
    },
    computed: {
        pageTitle() {
            return "Loan Equipment Location";
        },
        userName() {
            if (this.isLoggedIn()) {
                let user = this.userInfo();
                return user.name;
            }
        },
        canSave() {
            if (this.newLocation.status === undefined)
                return false;
            return (this.location[this.newLocation.status] ?
                this.location[this.newLocation.status].name != '' : this.location[this.newLocation.status] != '')
                && this.address[this.newLocation.status] != '' && this.newLocation.start_at_string != ''
        }
    },
    methods: {
        async loadLoanEquipment() {
            if (!this.loanEquipmentId)
                return false;

            try {
                this.isBusy = true;
                const loanEquipmentDetails = await this.EquipmentService.getEquipmentItemById(
                    this.loanEquipmentId,
                );
                this.loanEquipment = loanEquipmentDetails;
                if (loanEquipmentDetails.loan_equipments && loanEquipmentDetails.loan_equipments.length > 0) {
                    const lastLoanEquipment = loanEquipmentDetails.loan_equipments.slice(-1)[0];
                    this.currentEquipmentLoan = lastLoanEquipment;
                    this.loanEquipmentHistory = loanEquipmentDetails.loan_equipments;
                } else {
                    this.currentEquipmentLoan = { status: '', invoice_warranty_number: '', current_location: { name: '' } }
                    this.loanEquipmentHistory = [];
                }

                if (loanEquipmentDetails.calibration_history && loanEquipmentDetails.calibration_history.length > 0) {
                    this.calibration_date = loanEquipmentDetails.calibration_history[loanEquipmentDetails.calibration_history.length - 1].next_date;
                    this.calibration_date_display = this.$moment.unix(this.calibration_date).format("DD-MM-YYYY");
                }

                this.isBusy = false;
            } catch (error) {
                this.isBusy = false;
            }

        },
        generateUniqueId() {
            return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
        },
        submitLocation: async function (event) {
            event.preventDefault();

        },

        saveLocation: async function () {
            try {
                this.isBusy = true;
                this.newLocation.current_location = this.removeFields(this.location[this.newLocation.status]);
                this.newLocation.address = this.address[this.newLocation.status];

                await this.LoanEquipmentService.addLocation(
                    this.loanEquipmentId,
                    this.newLocation,
                );
                this.notifySuccess("New Location added successfully.");
                await this.$router.push({ name: "loan-equipment" });
            } catch (error) {
                console.log(error);
                this.notifyError("Couldn't create New Location");
            }
            finally {
                this.isBusy = false;
            }
        },
        notifyUser: function (type, message) {
            this.$breadstick.notify(
                ({ h, onClose }) => {
                    return h(Notification, {
                        props: {
                            type: type,
                            title: message,
                            close: onClose,
                        },
                    });
                },
                { position: "top-right" },
            );
        },
        formatStartAt(start_at) {
            return start_at == 0 || start_at == null ? "" : this.$moment.unix(start_at).format("DD-MM-YYYY");
        },
        userInfo() {
            return this.AuthService.getUserInfo();
        },
        isLoggedIn() {
            return this.AuthService.isLoggedIn();
        },
        loadStatusList: async function () {
            let result = await this.LoanEquipmentService.loadStatusList();
            this.statusList = result;
        },
        handleItemSelected(selectedItem) {
            if (selectedItem.address) {
                this.address[this.newLocation.status] = [selectedItem.address.address1,
                selectedItem.address.address2,
                selectedItem.address.city, selectedItem.address.county,
                selectedItem.address.country, selectedItem.address.postcode].join("\n");
            } else {
                this.address[this.newLocation.status] = "";
            }
        },
        removeFields(obj) {
            if (typeof obj === 'object' && obj !== null) {
                let { contact_id, name } = obj;
                return { contact_id, name };
            }
            return obj;
        },
        getTextColor(nextDate) {
            if (nextDate == null)
                return 'black';
            const currentDate = new Date();
            const expiryDate = new Date(nextDate * 1000);
            const daysDifference = Math.floor((expiryDate - currentDate) / (24 * 60 * 60 * 1000));

            if (daysDifference <= 7) {
                return 'red';
            } else if (daysDifference <= 14) {
                return '#FF9B00';
            } else {
                return 'black'; // Default color
            }
        }
    },
};
</script>
<style>
.image-checkbox-container {
    position: relative;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.file-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.checkbox-container {
    text-align: center;
    padding-top: 0.25rem;
    /* Adjust as needed */
}

.checkbox {
    display: inline-block;
    width: 1.5rem;
    /* Adjust size as needed */
    height: 1.5rem;
}

.upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed gray;
    cursor: pointer;
}

.upload-icon {
    width: 64px;
    height: 64px;
}
</style>
  